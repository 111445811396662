export default {
  methods: {
    urls_build: (url, queryParams = {}, fragment = '') => {

      // fragment refers to "bar" here: http://www.example.org/foo.html#bar
      if (fragment !== '') {
          fragment = '#' + fragment;
      }
      
      if (Object.entries(queryParams).length === 0) {
          return url + fragment 
      }       
      
      return  url + '?' + new URLSearchParams(queryParams) + fragment
    }
  }
}