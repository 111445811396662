var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_vm._v(" Mutation log ")])],1),_c('v-row',[_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.accounts,"item-text":"name","item-value":"account_id","label":"Account","clearable":"","prepend-icon":"account_balance"},model:{value:(_vm.selectedAccount),callback:function ($$v) {_vm.selectedAccount=$$v},expression:"selectedAccount"}})],1),_c('v-col',[_c('v-autocomplete',{attrs:{"items":_vm.activeUsers,"item-text":"name","item-value":"id","label":"User","clearable":"","prepend-icon":"person"},model:{value:(_vm.selectedUser),callback:function ($$v) {_vm.selectedUser=$$v},expression:"selectedUser"}})],1),_c('v-col',[_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary"},on:{"click":_vm.show}},[_vm._v("Show")])],1),_c('v-spacer')],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"options":_vm.options,"footer-props":{
      'items-per-page-options': _vm.datatables_rowsPerPageItems,
    },"server-items-length":_vm.totalItems,"show-expand":"","single-expand":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_vm._v(" Loading items.. ")]},proxy:true},{key:"item.tableau_link",fn:function(ref){
    var item = ref.item;
return [_c('a',{staticClass:"blue--text darken-1",attrs:{"href":_vm.getTableauURL(item),"target":"_blank"}},[_vm._v("Open")])]}},{key:"expanded-item",fn:function(ref){
    var headers = ref.headers;
    var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"4"}},[_c('ul',[_c('li',{class:{changed: item.headline1_changed}},[_c('strong',[_vm._v("Headline1:")]),_vm._v(" "+_vm._s(item.headline1_changed ? item.old_headline1 + ' -> ' + item.new_headline1 : item.new_headline1)+" ")]),_c('li',{class:{changed: item.headline2_changed}},[_c('strong',[_vm._v("Headline2:")]),_vm._v(" "+_vm._s(item.headline2_changed ? item.old_headline2 + ' -> ' + item.new_headline2 : item.new_headline2)+" ")]),_c('li',{class:{changed: item.headline3_changed}},[_c('strong',[_vm._v("Headline3:")]),_vm._v(" "+_vm._s(item.headline3_changed ? item.old_headline3 + ' -> ' + item.new_headline3 : item.new_headline3)+" ")])])]),_c('v-col',{attrs:{"md":"8"}},[_c('ul',[_c('li',{class:{changed: item.description_line1_changed}},[_c('strong',[_vm._v("Description line 1:")]),_vm._v(" "+_vm._s(item.description_line1_changed ? item.old_description_line1 + ' -> ' + item.new_description_line1 : item.new_description_line1)+" ")]),_c('li',{class:{changed: item.description_line2_changed}},[_c('strong',[_vm._v("Description line 2:")]),_vm._v(" "+_vm._s(item.description_line2_changed ? item.old_description_line2 + ' -> ' + item.new_description_line2 : item.new_description_line2)+" ")]),_c('li',{class:{changed: item.path1_changed}},[_c('strong',[_vm._v("Path1:")]),_vm._v(" "+_vm._s(item.path1_changed ? item.old_path1 + ' -> ' + item.new_path1 : item.new_path1)+" ")]),_c('li',{class:{changed: item.path2_changed}},[_c('strong',[_vm._v("Path2:")]),_vm._v(" "+_vm._s(item.path2_changed ? item.old_path2 + ' -> ' + item.new_path2 : item.new_path2)+" ")])])])],1)],1)],1)]}},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(_vm.datatables_footerPageText(_vm.totalItemsIsEstimate, _vm.estimatedItems, props))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }