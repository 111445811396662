<template>
  <v-card>
    <v-card-title>
      <v-container fluid class="pa-0">
        <v-row>
          <v-col>
            Mutation log
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-autocomplete v-model="selectedAccount" :items="accounts" item-text="name" item-value="account_id" label="Account" 
              clearable prepend-icon="account_balance">
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-autocomplete v-model="selectedUser" :items="activeUsers" item-text="name" item-value="id" label="User" 
              clearable prepend-icon="person">
            </v-autocomplete>
          </v-col>

          <v-col>
            <v-btn class="mt-2" color="primary" @click="show">Show</v-btn>
          </v-col>

          <v-spacer></v-spacer>
        </v-row>
      </v-container>

    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        'items-per-page-options': datatables_rowsPerPageItems,
      }"
      :server-items-length="totalItems"
      show-expand
      single-expand
      class="elevation-1"
    >
      <template v-slot:loading>
        Loading items..
      </template>

      <template v-slot:[`item.tableau_link`]="{ item }">
        <a :href="getTableauURL(item)" target="_blank" class="blue--text darken-1">Open</a> 
      </template>

      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-container fluid>
            <v-row>
              <v-col md="4">
                <ul>
                  <li :class="{changed: item.headline1_changed}"><strong>Headline1:</strong> 
                    {{ item.headline1_changed ? item.old_headline1 + ' -> ' + item.new_headline1 : item.new_headline1 }}
                  </li>
                  <li :class="{changed: item.headline2_changed}"><strong>Headline2:</strong> 
                    {{ item.headline2_changed ? item.old_headline2 + ' -> ' + item.new_headline2 : item.new_headline2 }}
                  </li>
                  <li :class="{changed: item.headline3_changed}"><strong>Headline3:</strong> 
                    {{ item.headline3_changed ? item.old_headline3 + ' -> ' + item.new_headline3 : item.new_headline3 }}
                  </li>
                </ul>
              </v-col>
              <v-col md="8">
                <ul>
                  <li :class="{changed: item.description_line1_changed}"><strong>Description line 1:</strong> 
                    {{ item.description_line1_changed ? item.old_description_line1 + ' -> ' + item.new_description_line1 : item.new_description_line1 }}
                  </li>
                  <li :class="{changed: item.description_line2_changed}"><strong>Description line 2:</strong> 
                    {{ item.description_line2_changed ? item.old_description_line2 + ' -> ' + item.new_description_line2 : item.new_description_line2 }}
                  </li>
                  <li :class="{changed: item.path1_changed}"><strong>Path1:</strong> 
                    {{ item.path1_changed ? item.old_path1 + ' -> ' + item.new_path1 : item.new_path1 }}
                  </li>
                  <li :class="{changed: item.path2_changed}"><strong>Path2:</strong> 
                    {{ item.path2_changed ? item.old_path2 + ' -> ' + item.new_path2 : item.new_path2 }}
                  </li>
                </ul>
              </v-col>
            </v-row>
          </v-container>
          
        </td>
      </template>

      <template v-slot:[`footer.page-text`]="props">
        {{ datatables_footerPageText(totalItemsIsEstimate, estimatedItems, props) }}
      </template>

    </v-data-table>
  </v-card>
</template>

<script>
import dataTablesMixin from '@/mixins/datatables'
import urlsMixin from '@/mixins/urls'
import { TABLEAU_MUTATION_LOG_DASHBOARD, TABLEAU_MUTATION_LOG_MAX_INTERVAL_DAYS } from '@/constants';

export default {
  name: 'MutationLogTable',

  data () {
    return {
      headers: [
        { text: 'Uploaded', value: 'entry_at' },
        { text: 'Account', value: 'account_name' },
        { text: 'Feed', value: 'feed_name' },
        { text: 'User', value: 'user_name' },
        { text: 'Campaign', value: 'campaign_name' },
        { text: 'Topic', value: 'topic' },
        { text: 'Gender', value: 'gender' },
        { text: 'Age group', value: 'age_group' },
        { text: 'Match type', value: 'match_type' },
        { text: '# items', value: 'feed_item_count' },
        { text: 'Tableau', value: 'tableau_link', sortable: false},
        { text: '', value: 'data-table-expand' },
      ],
      items: [],
      loading: false,
      totalItems: 0,
      estimatedItems: 0,
      totalItemsIsEstimate: false,
      options: {},
      selectedAccount: null,
      selectedUser: null,
      lsOptions: 'mutationLog_options',
      lsFilters: 'mutationLog_filters',
      mutationLogEndpoint: '/a/google/feed-mutation-log',
    }
  },

  mixins: [dataTablesMixin, urlsMixin],

  computed: {
    accounts () {
      return this.$store.state.google.accounts
    },

    activeUsers () {
      return this.$store.state.system.activeUsers
    },
  },

  watch: {
    options: {
      handler () {
        this.fetchData()
      },
      deep: true,
    },
  },

  methods: {
    fetchData: function () {
      this.loading = true
      var myURL = this.mutationLogEndpoint + '?xfields='

      // get fields from headers and additional cols required by expandable rows
      var additionalCols = ['id', 'account_id',
        'old_headline1', 'old_headline2', 'old_headline3', 'old_description_line1', 'old_description_line2', 'old_path1', 'old_path2',
        'new_headline1', 'new_headline2', 'new_headline3', 'new_description_line1', 'new_description_line2', 'new_path1', 'new_path2',
        'headline1_changed', 'headline2_changed', 'headline3_changed', 'description_line1_changed', 'description_line2_changed', 'path1_changed', 'path2_changed'
      ]
      myURL = this.datatables_addHeaderFieldsToURI(myURL, this.headers, ['tableau_link', 'data-table-expand'], additionalCols)

      // get paging and sorting from options via data table component and save to localStorage
      myURL = this.datatables_processURIOptions(myURL, this.options)
      localStorage.setItem(this.lsOptions, JSON.stringify(this.options))

      // filter account
      if (this.selectedAccount) {
        myURL += '&account_id=' + this.selectedAccount
      }

      // filter user
      if (this.selectedUser) {
        myURL += '&user_id=' + this.selectedUser
      }

      this.$http.get(myURL).then(resp => {
        if (resp.data.data) {
          this.items = resp.data.data
          this.totalItemsIsEstimate = resp.headers['x-total-count-estimated'] === 'true' ? true : false
          var itemCounts = this.datatables_getItemCounts(this.totalItemsIsEstimate, Number(resp.headers['x-total-count']))
          this.totalItems = itemCounts.totalItems
          this.estimatedItems = itemCounts.estimatedItems
        } else {
          this.items = []
        }
      })
      .catch(errResp => {
        this.$store.commit('system/setAPIError', { method: 'GET', url: myURL, description: errResp.response.data.err_description, ext_msg: errResp.response.data.external_message })
      })
      .finally(() => this.loading = false)
    },

    show: function () {
      let filterObj = {
        'selectedAccount': this.selectedAccount,
        'selectedUser': this.selectedUser
      }
      localStorage.setItem(this.lsFilters, JSON.stringify(filterObj))

      this.options.page = 1;
      this.fetchData()
    },
    
    getTableauURL: function (item) {
      const startDate = this.$dateTime.fromSQL(item.entry_at)
      const dayDifference = Math.abs(startDate.diffNow('days').toObject().days)

      // restrict end_date to no more than TABLEAU_MUTATION_LOG_MAX_INTERVAL_DAYS after start date
      // this is to limit the load on Tableau
      const end_date = dayDifference > TABLEAU_MUTATION_LOG_MAX_INTERVAL_DAYS ? 
        startDate.plus({ days: TABLEAU_MUTATION_LOG_MAX_INTERVAL_DAYS }).toISODate() : 
        this.$dateTime.now().toISODate()

      const queryParams = {
        account_id: item.account_id,
        gender: item.gender,
        age_group: item.age_group,
        ad_group_topic: item.topic,
        start_date: startDate.toISODate(),
        end_date: end_date,
      }

      Object.entries(queryParams).forEach(([key, value]) => {
        if (value === 'Aggregated') {
          delete queryParams[key]
        }
      })

      return this.urls_build(TABLEAU_MUTATION_LOG_DASHBOARD, queryParams)
    }

  },

  created: function () {
    // get data and refresh regularly to prevent stale data from being shown
    this.timer = setInterval(this.fetchData, this.datatables_refreshIntervalMs)

    // if present, use local sorting/paging options
    if (localStorage.getItem(this.lsOptions)) {
      this.options = JSON.parse(localStorage.getItem(this.lsOptions))
      // setting multisort here: in v-data-table didn't work
      this.options['multiSort'] = true
    } else {
      this.options = {'multiSort': true}
    }

    // if present, use local filters
    if (localStorage.getItem(this.lsFilters)) {
      let filters = JSON.parse(localStorage.getItem(this.lsFilters))
      if (filters['selectedAccount']) { this.selectedAccount = filters['selectedAccount'] }
      if (filters['selectedUser']) { this.selectedUser = filters['selectedUser'] }
    }
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },
}
</script>

<style scoped>
  .changed {
    background-color: yellow;
  }
</style>